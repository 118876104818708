<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            :label="'Users'"
            :icon="value.view.display.icon"
            :actions="actions"
            @onCreate="onCreate"
            right
          ></FormSection>

          <UserItemVue
            class="mt-2"
            v-for="user in users"
            :key="user.id"
            :value="user"
            @click="onSelect"
            @onTerminate="onTerminate"
            @onOpen="onSelect"
            @mouseover="onHover"
            @mouseleave="() => {}"
            :active="activeUserId === user.id"
          ></UserItemVue>
        </v-col>
      </v-row>
    </template>
  </AView>
</template>
    
    
    
  <script>
import { mapState } from "vuex";
import UserItemVue from "../../../../../../components/wad/organisms/users/UserItem.vue";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GUI } from "../../../../GrapUI.config.js";
import { UserCreation } from "../../app.gh.js";

export default {
  name: "UsersList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    UserItemVue,
  },
  computed: mapState("UserStore", ["users", "activeUserId"]),
  async created() {
    this.$store.dispatch("UserStore/GetUsersList", {});
  },
  data() {
    return {
      page: 1,
      scene: GUI.scene,
      model: null,
      data: {
        services: [],
      },
      actions: [
        {
          name: "Create",
          description: "Will create new User",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
      listTimeout: undefined,
    };
  },
  methods: {
    async onCreate() {
      await this.scene.add(this.value, UserCreation, {});
    },
    onEdit() {},
    onHover(value) {
      this.$store.commit("UserStore/setActiveId", value.id);
    },
    onSelect(user) {
      this.$store.dispatch("UserStore/SetActiveUser", {
        from: this.value,
        user,
      });
    },
    onTerminate(user) {
      this.$store.dispatch("UserStore/DeleteUser", {
        user,
      });
    },
  },
  watch: {
    activeServiceId(newVal) {
      if (newVal) {
        const index = this.services.findIndex((el) => el.id === newVal);
        this.active = index;
      }
    },
    startLetter(newVal) {
      if (this.listTimeout) clearTimeout(this.listTimeout);

      this.listTimeout = setTimeout(() => {
        this.$store.dispatch("UserStore/GetUsersList", {
          startLetter: this.letters[newVal].letter,
        });
      }, 200);
    },
  },
};
</script>
    
    
    
    <style scoped lang="scss" >
.users-list {
  height: 100%;
  width: 100%;
  // ::v-deep button {
  //   background: white !important;
  // }
}

.v-slide-group__wrapper {
  padding-top: 20px;
}
</style>