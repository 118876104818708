<template>
  <v-card
    :loading="loading"
    class="user-item bg-grey-gradient"
    v-bind="$attrs"
    @click="$emit('click', value)"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <div
      class="user-status"
      :class="{
        primary: value.status === 'TERMINATED',
        'teal darken-1': value.status === 'ACTIVE',
        'yellow darken-1': value.status === 'INVITED',
      }"
    >
      {{ value.status }}
    </div>
    <div>
      <v-slide-group class="ml-2 my-2" center-active show-arrows>
        <v-slide-item
          v-for="role in value.userRoles.slice(0, 3)"
          :key="role.id"
          v-slot="{ toggle }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ma-2"
                dark
                label
                text-color="white"
                @click="toggle"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left> mdi-label </v-icon>
                {{ role.role.name }}
              </v-chip>
            </template>
            <span>{{ role.role.description }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>

      <v-row>
        <v-col cols="4">
          <div class="user-type-img control">
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img
                :style="{
                  backgroundColor:
                    value.profile.avatar && value.profile.avatar.url
                      ? 'white'
                      : value.profile.color,
                }"
                :src="
                  value.profile.avatar && value.profile.avatar.url
                    ? value.profile.avatar.url
                    : value.profile.avatarURL
                "
                :lazy-src="
                  value.profile.avatar && value.profile.avatar.url
                    ? value.profile.avatar.url
                    : value.profile.avatarURL
                "
              ></v-img>
              <div
                class="user-type"
                :class="{
                  employee: value.employee,
                  customer: value.customer,
                }"
              >
                <span class="user-type-label" v-if="value.employee">
                  EMPLOYEE</span
                >
                <span class="user-type-label" v-if="value.customer">
                  CUSTOMER</span
                >
              </div>
            </v-avatar>
            <span class="user-name" v-if="value.profile">
              {{ value.profile.name }}
            </span>
            <span class="last-login"> {{ formatDate(value.last_login) }} </span>
          </div>
        </v-col>
        <v-col cols="8" class="pt-0 mt-0">
          <v-card-text class="pt-0 mt-0">
            <v-row class="justify-space-between">
              <v-col cols="12" class="pa-0 ma-0">
                <v-row align="center" class="mx-0"> </v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      link
                      v-if="value.profile"
                      v-bind="attrs"
                      v-on="on"
                      @click.prevent.stop="copy(value.profile.work_email)"
                    >
                      <v-list-item-title>{{
                        value.profile.work_email
                      }}</v-list-item-title>

                      <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>

                <v-list-item link>
                  <v-list-item-title>{{ value.email }}</v-list-item-title>

                  <v-list-item-icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item v-if="value.settings.timezone">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      value.settings.timezone.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      value.settings.timezone.code
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-map-clock </v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item v-if="value.settings.country">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      value.settings.country.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >ISO: {{ value.settings.country.iso2 }}/{{
                        value.settings.country.iso3
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      size="24"
                      :style="{ width: '24px' }"
                      color="primary"
                    >
                      {{
                        `fi fi-${String(
                          value.settings.country.iso2
                        ).toLowerCase()}`
                      }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider class="mx-4"></v-divider>
      <div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="onOpen"> Details </v-btn>
          <v-btn color="primary" text @click="onTerminate"> TERMINATE </v-btn>
          <!-- <v-btn color="primary" text @click="onOpen"> Message </v-btn> -->
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";

export default {
  props: {
    value: {},
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formatter: FormatterHelper,
      formatDate: FormatterHelper.formatDate,
      loading: false,
      show: false,
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
    onTerminate() {
      this.$emit("onTerminate", this.value);
    },
    async copy(text) {
      await navigator.clipboard.writeText(text);
    },
  },
};
</script>


<style lang="scss" scoped>
.user-item {
  z-index: 2;
  overflow: hidden;
  .user-type-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    // -webkit-mask-image: -webkit-gradient(
    //   linear,
    //   left top,
    //   left right,
    //   from(rgba(0, 0, 0, 1)),
    //   to(rgba(0, 0, 0, 0))
    // );
    // mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3));
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .user-name {
    text-transform: uppercase;

    margin-top: 8px;
  }

  .last-login {
    font-size: 12px;
    margin-top: 8px;
    color: #cfd8dc !important;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .user-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .user-type {
    position: absolute;
    right: -45px;
    top: 11px;
    width: 140px;
    transform: rotate(45deg);
    height: 28px;
    padding: 4px;

    &.employee {
      background-color: #035f9d;
    }
    &.customer {
      background-color: #a71a47;
    }
    .user-type-label {
      left: 0;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
</style>